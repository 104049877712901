import {useState, useCallback, useMemo, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useHandleError } from "../Tools/HandleError/HandleError";
import { GridColDef } from "@mui/x-data-grid";
import CollapseCard from "../Tools/Display/CollapseCard";
import CustomButton from "../Tools/Action/Button/ButtonCustom";
import Table from "./Table";
import getMinWidth from "../Tools/Utils/getMaxColumnTextWidth";
import { TableColumnInterface } from "../Tools/Display/TablePaginationPro";
import axios from "axios";
import * as request from "../Tools/Utils/APIRequests/request";

type DataTableProps = {
    import: boolean;
    type_de_table: string;
    nom: string;
    date_modification: string;
    nouveau_nom: string;
};

export default function Migration() {
    const [file, setFile] = useState<File>();
    const [isUploaded, setIsUploaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [dataPath, setDataPath] = useState<string>("");
    const [filtered, setFiltered] = useState<any[]>([]);
    const handleError = useHandleError();
    const navigate = useNavigate();
    const navigateToDisconnect = useCallback(() => navigate("disconnect"), [navigate]);
    const handleChangeFile = useCallback((newFile: File) => setFile(newFile), []);

    const upload = async () => {
        if (!file)
            return;

        const source = axios.CancelToken.source();

        setIsUploaded(true);
        setIsLoading(true);

        const req = await request.post(
            `${process.env.REACT_APP_LEA_API}/import/create`,
            { "access_file": file },
            { headers: { "Content-type": "multipart/form-data" } },
            source
        );

        if (req.isSuccessful) {
            const tables = req.response.data.data.tables.map((obj: DataTableProps, index: number) => ({
                ...obj,
                id: index,
                date_calcul: "2023-12-31"
            }));

            setDataPath(req.response.data.data.chemin);
            setData(tables);
            setFiltered(tables);
            setIsLoading(false);
        } else if (req.isSessionExpired) {
            handleError(req.error, () => {
                setIsLoading(false);
                navigateToDisconnect();
            }, req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE, true);
        } else if (req.error.response)
            handleError(req.error, () => {
                setIsLoading(false);
            }, req.error.response.data.data.error);
        return () => {
            source.cancel("Component unmounted.");
        };
    };

    const columns: GridColDef[] = useMemo(() => [
        {
            field: "type_de_table",
            headerName: "Type de table",
            minWidth: getMinWidth(data, "type_de_table", "Type de table"),
            flex: 1,
            type: "string",
            renderCell: (params) => params.row.type_de_table ?? "",
            valueGetter: (params) => params.row.type_de_table ?? ""
        },
        {
            field: "nom",
            headerName: "Ancien nom de la table",
            minWidth: getMinWidth(data, "nom", "Ancien nom de la table"),
            flex: 1,
            type: "string",
            renderCell: (params) => params.row.nom ?? "",
            valueGetter: (params) => params.row.nom ?? ""
        },
        {
            field: "date_modification",
            headerName: "Date de la modification",
            type: "date",
            minWidth: getMinWidth(data, "date_modification", "Date de la modification"),
            flex: 1,
            renderCell: (params) => params.row.date_modification ?? "",
            valueGetter: (params) => params.row.date_modification ?? ""
        },
        {
            field: "nouveau_nom",
            headerName: "Nouveau nom de la table",
            minWidth: getMinWidth(data, "nouveau_nom", "Nouveau nom de la table"),
            flex: 1,
            type: "string",
            isEditable: true,
            renderCell: (params) => params.row.nouveau_nom ?? "",
            valueGetter: (params) => params.row.nouveau_nom ?? ""
        },
        {
            field: "date_calcul",
            headerName: "Date de calcul",
            minWidth: getMinWidth(data, "date_calcul", "Date de calcul"),
            flex: 1,
            type: "date",
            isEditable: true,
            renderCell: (params) => params.row.date_calcul ?? "",
            valueGetter: (params) => params.row.date_calcul ?? ""
        }
    ], [data]);

    useEffect((): void => {
        if (!localStorage.getItem('JWT')) {
            navigateToDisconnect();
            setIsLoading(false);
            return;
        }
    }, []);

    return (
        <div className={"pb-8 px-8"} style={{height: "calc(100% - 70px)"}}>
            <div className={"flex flex-col h-full w-full space-y-5 p-5 font-worksans"}>
                <CollapseCard title={"Migration"} alwaysOpen={true}>
                    <div className={"flex flex-col w-full h-full justify-center items-start space-y-3 py-5 px-8"}>
                        <p className={"text-start text-gray-600 text-base"}>
                            Cette page permet la migration des données 2022 LEAifc Groupe vers LEA WEB 2<br/>
                            Ces données concernent les bases de données : bibliothèque, Salarié, Résultat (pas Dossier)<br/>

                            Pour plus d'information, <a target={`_blank`} className={`underline text-blue-600 hover:text-blue-800 visited:text-purple-600`} href={process.env.PUBLIC_URL + "/User-guide/import_donnees_ifc.pdf"}>consulter le guide</a>

                        </p>
                        <h3 style={{ marginTop: "2em", fontSize: "20px", fontWeight: "600", color: "#4F4D56" }}>Import de données</h3>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input
                                className={"relative m-0 block max-w-xl flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"}
                                style={{ width: "100%" }}
                                aria-describedby={"file_input_help"}
                                id={"file_input"}
                                type={"file"}
                                accept={".mdb"}
                                onChange={input => {
                                    const file: File = input!.target!.files![0];
                                    setFile(file);
                                    handleChangeFile(file);
                                }}
                            />
                            <div className={"flex flex-row space-x-5"} style={{ marginLeft: "40px", overflowWrap: "break-word" }}>
                                <CustomButton text={"Téléverser"} disabled={!file} onClick={upload}/>
                            </div>
                        </div>
                        <p className={"text-start text-gray-600 text-base"}>
                            Fichier base de données au format LEAifc Groupe, Extension MDB
                        </p>
                            <Table
                                file={file}
                                isUploaded={isUploaded}
                                isTableLoading={isLoading}
                                columns={columns as TableColumnInterface[]}
                                data={data}
                                dataPath={dataPath}
                                rows={filtered}
                                handleFilterSearch={setFiltered}
                            />
                    </div>
                </CollapseCard>
            </div>
        </div>
    );
}
